<template>
	<div
		v-show="!posi || (posi && posi.pc && isPC) || (posi && posi.m && !isPC)"
		class="ads-item"
		:style="sty">
		<div
			v-show="isAdFilled"
			class="ads-borded">
			<div class="ads-borded-title">Advertisement</div>
			<Adsense v-bind="ads"></Adsense>
		</div>
		<div
			v-show="$store.state.showDebug"
			class="ad-msg">
			{{ ads }}
		</div>
	</div>
</template>

<script>
import '@/css/ads.scss'
export default {
	props: {
		ads: {
			required: true,
		},
		// 如果广告在列表，传入广告在列表中的位置.
		// {
		//   如果是pc的列表
		//   pc: {
		//     colNum: 3 一共3列的列表,
		//     rowAt: 2  在第二行下面出现
		//   },
		//   如果是移动端的列表，只有单列
		//   m: 2  在第二行下面出现
		//
		// }
		//
		posi: {
			require: false,
			type: Object,
			default: null
		},
	},
	data() {
		return {
			isAdFilled: true, // 默认显示
		}
	},
	mounted() {
		// this.observeAdStatus()
	},
	computed: {
		isPC() {
			return this.$store.state.deviceType === 'desktop'
		},
		sty() {
			if (this.posi) {
				if (this.isPC && this.posi.pc) {
					return {
						gridRowStart: this.posi.pc.rowAt,
						gridColumn: `1 / ${this.posi.pc.colNum + 1}`,
					}
				} else if (!this.isPC && this.posi.m) {
					return {
						gridRowStart: this.posi.m,
					}
				}
			} else return {}
		},
	},
	methods: {
		observeAdStatus() {
			const ads = this.$refs.adsense

			const observer = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					if (mutation.attributeName === 'data-ad-status') {
						console.log('Attribute changed:', mutation.target.getAttribute('data-ad-status'))
						this.isAdFilled = mutation.target.getAttribute('data-ad-status') !== 'unfilled'
					}
				})
			})

			observer.observe(ads, {
				attributes: true,
				attributeFilter: ['data-ad-status'],
			})

			// 初始化检查
			this.isAdFilled = ads.getAttribute('data-ad-status') !== 'unfilled'
		},
	},
}
</script>