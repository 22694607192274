import { mapGetters } from 'vuex'
// const navModule_1 = () => import('@/pages/modules/navModule_1.vue') //代码切割

export default {
	computed: {
		...mapGetters({
			host: 'host',
			showDebug: 'showDebug',
			deviceType: 'deviceType',
			adsensConfig: 'adsensConfig',
			domainConfig: 'domainConfig',
		}),
		isPC () {
			return this.deviceType === 'desktop'
		},
	},
}
