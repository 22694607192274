import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/index.vue'),
	},
	{
		path: '/all',
		name: 'all-list',
		component: () => import(/* webpackChunkName: "home" */ '../pages/all-list.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		component: () => import('../pages/disclaimer.vue'),
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../pages/404.vue'),
	},
	{
		path: '/:title',
		name: 'detail',
		component: () => import('../pages/detail.vue'),
	},

	
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
