<template>
	<footer>
		<div class="foot-nav-container pc_padding">
			<div class="footer-about">
				<div class="footer-item-title">About us</div>
				<div class="footer-item-content">
					{{ domainConfig['ABOUTUS'] }}
				</div>
			</div>
			<div class="footer-side-row">
				<div class="footer-side-resource">
					<div class="footer-item-title">Resources</div>
					<div class="resource">
						<div
							class="footer-item-content cursor"
							@click="goto('terms')">
							Terms of services
						</div>
						<div
							class="footer-item-content cursor mtc"
							style="margin-top: 12px"
							@click="goto('privacy')">
							Privacy policy
						</div>
						<div
							class="footer-item-content cursor mtc"
							style="margin-top: 12px"
							@click="goto('disclaimer')">
							Disclaimer
						</div>
					</div>
				</div>
				<div class="footer-side-contact">
					<div class="footer-item-title">Contact us</div>
					<div
						class="footer-item-content"
						style="margin-top: 12px">
						<a
							class="footer-item-content"
							:href="`mailto:${domainConfig['IAMEMAIL']}`">
							{{ domainConfig['IAMEMAIL'] }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="corner-module pc_padding">
			<br />
			Copyright © 2024 {{domainConfig['iamkeywords']}}. All Rights Reserved.
		</div>
	</footer>
</template>

<script>

export default {
	props: ['title'],
	name: 'FootModule1',
	data() {
		return {}
	},
	computed: {
		desc() {
			return `Embrace the power of history with ${this.domainConfig['IAmURL']}. Each day brings new insights into the events that defined our world, inspiring a deeper understanding of our shared past. `
		},
	},
	methods: {
		goto(where) {
			console.log(this.$route.path)
			this.$router.push({ name: where })
		},
	},
}
</script>
